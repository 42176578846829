<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("UsersPermissions") }}</label>

    </div>
    <!-- Start Dialog Delete -->
    <v-dialog v-model="dialogDelete" :width="config.deleteModalWidth">
      <v-card>
        <v-toolbar color="third" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">
            {{ $t("AreYouSureYouWantToDelete") }}
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogDelete = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="deleteItemConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog Delete -->

    <v-toolbar flat>
      <v-toolbar-title>{{ $t(config.tableName) }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-dialog
        v-model="dialog"
        :width="config.modalFormWidth"
        @click:outside="closeModal"
      >
        <template> -->

          
            <v-btn
            v-if="
                user &&
                user.permissions &&
                user.permissions.includes('store_permission')
              "
              color="info"
              @click="newData"
              class="mb-2"
              small
            >
              <span class="addNewLabel">{{ $t("AddNew") }}</span>

              <v-icon class="my-0 mr-2 ml-2" dense>mdi-file</v-icon>
            </v-btn>
          <div>
            <!-- <download-excel
              class="
                mb-2
                mx-5
                cursorPointer
                v-btn
                theme--dark
                v-size--small
                success
                v-btn v-btn--is-elevated v-btn--has-bg
              "
              :data="table.items"
              :fields="table.itemExport"
              type="xls"
              :header="$t(config.tableName)"
              :worksheet="$t(config.tableName)"
              :name="$t(config.tableName)"
            >
              <span class="excelLabel">{{ $t("ExportExcel") }}</span>
              <v-icon
                class="my-0 mr-2 ml-2"
                style="color: #fff !important"
                dense
                >mdi-export</v-icon
              >
            </download-excel>

            <v-btn
              color="danger"
              @click="loadPDF"
              dark
              small
              class="mb-2 mr-2 ml-2"
            >
              <span class="pdfLabel"> {{ $t("LoadPDF") }} </span>
              <v-icon class="my-0 mr-2 ml-2" dense>mdi-eye</v-icon>
            </v-btn> -->
          </div>
        <!-- </template> -->

        <!-- <div v-if="dialog">
          <v-card>
            <v-card-title>
              <span class="text-h5" v-if="formData.id > 0">
                {{ $t("Edit") }}
              </span>
              <span class="text-h5" v-else> {{ $t("Add") }} </span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col class="py-0" :cols="4">
                    <div
                      :class="{
                        'has-error': errors.has(`addEditValidation.name`),
                      }"
                    >
                      <label>{{ $t("Name") }}</label>
                      <v-text-field
                        class="d-block my-2"
                        type="text"
                        name="name"
                        data-vv-scope="addEditValidation"
                        v-validate="'required'"
                        :data-vv-as="$t('Name')"
                        hide-details
                        dense
                        outlined
                        v-model="formData.name"
                      ></v-text-field>
                    </div>

                    <div
                      class="help-block"
                      v-if="errors.has(`addEditValidation.name`)"
                    >
                      {{ errors.first(`addEditValidation.name`) }}
                    </div>
                  </v-col>

                  <v-col class="py-0" :cols="4">
                    <div
                      :class="{
                        'has-error': errors.has(`addEditValidation.email`),
                      }"
                    >
                      <label>{{ $t("Email") }}</label>
                      <v-text-field
                        class="d-block my-2"
                        type="text"
                        name="email"
                        data-vv-scope="addEditValidation"
                        v-validate="'required|email'"
                        :data-vv-as="$t('Email')"
                        hide-details
                        dense
                        outlined
                        v-model="formData.email"
                      ></v-text-field>
                    </div>

                    <div
                      class="help-block"
                      v-if="errors.has(`addEditValidation.email`)"
                    >
                      {{ errors.first(`addEditValidation.email`) }}
                    </div>
                  </v-col>

                  <v-col class="py-0" :cols="4" v-if="formData.id == 0">
                    <div
                      :class="{
                        'has-error': errors.has(`addEditValidation.password`),
                      }"
                    >
                      <label>{{ $t("Password") }}</label>
                      <v-text-field
                        class="d-block my-2"
                        type="password"
                        name="password"
                        data-vv-scope="addEditValidation"
                        v-validate="'required'"
                        :data-vv-as="$t('Password')"
                        hide-details
                        dense
                        outlined
                        v-model="formData.password"
                      ></v-text-field>
                    </div>

                    <div
                      class="help-block"
                      v-if="errors.has(`addEditValidation.password`)"
                    >
                      {{ errors.first(`addEditValidation.password`) }}
                    </div>
                  </v-col>

                  <v-col class="py-0" :cols="4" v-if="formData.id == 0">
                    <div
                      :class="{
                        'has-error': errors.has(
                          `addEditValidation.confirm_password`
                        ),
                      }"
                    >
                      <label>{{ $t("ConfirmPassword") }}</label>
                      <v-text-field
                        class="d-block my-2"
                        type="password"
                        name="confirm_password"
                        data-vv-scope="addEditValidation"
                        v-validate="'required'"
                        :data-vv-as="$t('ConfirmPassword')"
                        hide-details
                        dense
                        outlined
                        v-model="formData.confirm_password"
                      ></v-text-field>
                    </div>

                    <div
                      class="help-block"
                      v-if="errors.has(`addEditValidation.confirm_password`)"
                    >
                      {{ errors.first(`addEditValidation.confirm_password`) }}
                    </div>
                  </v-col>

                  <v-col class="py-0" :cols="4">
                    <div
                      :class="{
                        'has-error': errors.has(`addEditValidation.roles`),
                      }"
                    >
                      <label>{{ $t("Roles") }}</label>
                      <v-autocomplete
                        class="d-block my-2"
                        name="roles"
                        data-vv-scope="addEditValidation"
                        v-validate="'required'"
                        :data-vv-as="$t('Roles')"
                        hide-details
                        dense
                        outlined
                        v-model="formData.roles"
                        item-value="id"
                        item-text="name"
                        :items="roleList"
                        clearable
                        multiple
                      ></v-autocomplete>
                    </div>

                    <div
                      class="help-block"
                      v-if="errors.has(`addEditValidation.roles`)"
                    >
                      {{ errors.first(`addEditValidation.roles`) }}
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-container>
                <v-row class="float-left">
                  <v-btn
                    class="mr-2 ml-2"
                    color="danger"
                    dark
                    text
                    small
                    @click="closeModal"
                  >
                    {{ $t("Cancel") }}
                  </v-btn>
                  <v-btn
                    class="mr-2 ml-5"
                    color="success"
                    dark
                    outlined
                    small
                    @click="save"
                  >
                    {{ $t("Save") }}
                  </v-btn>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </div>
      </v-dialog> -->
    </v-toolbar>

    <!-- Start DataTable -->
    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      :items-per-page="table.itemsPerPage"
      :page.sync="table.page"
      hide-default-footer
      class="elevation-1"
      :loading-text="$t('LoadData')"
    >
      <template v-slot:no-data>
        <v-alert class="mt-5 white-clr" color="third">
          {{ $t("NoData") }}
        </v-alert>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="user &&
                user.permissions &&
                user.permissions.includes('update_permission')
          "
          class="my-0 mr-2 ml-2"
          dense
          @click="
             $router.push({ name: 'userPermissionAddEdit', params: { id: item.id } })
          "
          color="success"
          >mdi-pencil</v-icon
        >

        <v-icon

          v-if="user &&
                user.permissions &&
                user.permissions.includes('delete_permission')
          "
          class="my-0 mr-2 ml-2"
          dense
          @click="
            formData = Object.assign({}, item);
            dialogDelete = true;
          "
          color="danger"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <!-- End DataTable -->

    <!-- Start Pagination -->
    <div class="text-center pt-2">
      <v-pagination
        v-if="table.length"
        total-visible="5"
        v-model="table.page"
        :length="table.length"
        @input="changePage(table.page)"
      ></v-pagination>
    </div>

    <!-- End Pagination -->
  </div>
</template>

<script>
export default {
  name: "userPermission",
  data() {
    return {
         user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      filtersItems: [
        { text: this.$t("All"), value: null },
        { text: this.$t("Active"), value: 1 },
        { text: this.$t("InActive"), value: 0 },
      ],
      config: {
        tableName: "UsersPermissionsTable",
        crudHeader: "UsersPermissions",
        crudApi: "userPermission",
        filterWidth: "20rem",
        deleteModalWidth: "30rem",
        modalFormWidth: "50rem",
      },
      table: {
        page: 1,
        length: null,
        itemsPerPage: 10,
        loading: false,
        items: [],
        itemExport: {
          "#": "id",
          الاسم: {
            field: "name",
            callback: (item) => {
              if (item) return item;
            },
          },
          "البريد الإلكتروني": {
            field: "email",
            callback: (item) => {
              if (item) return item;
            },
          },
          الصلاحيات: {
            field: "permissions",
            callback: (item) => {
              if (item) return item;
            },
          },
        },
        headers: [
          { text: "#", value: "id", width: "5rem" },
          {
            text: this.$t("Name"),
            value: "name",
            width: "10rem",
          },
          {
            text: this.$t("permissions"),
            value: "permissions",
            width: "8rem",
          },
        ],
      },
      formData: {
        id: 0,
        name: null,
        permissions: [],
      },
      drawer: false,
      dialog: false,
      dialogDelete: false,
      roleList: [
        {
          id: 1,
          name: "constant",
        },
        {
          id: 2,
          name: "store",
        },
        {
          id: 3,
          name: "orders",
        },
        {
          id: 4,
          name: "reports",
        },
        {
          id: 5,
          name: "users",
        },
        {
          id: 6,
          name: "imports",
        },
      ],
    };
  },
  methods: {
    loadPDF() {
      window.open(`/#/reports/${this.config.crudApi}`);
    },
    newData() {
      // this.clearData();
      this.$router.push({name: 'userPermissionAddEdit'})
    },
    clearData() {
      this.formData = {
        id: 0,
        name: null,
        permissions: [],
      };
      this.$validator.reset();
    },
    deleteItemConfirm() {
      this.deleteData(
        `${this.config.crudApi}/removeData`,
        this.formData.id
      ).then(() => {
        this.getDataFromApi();
        this.dialogDelete = false;
      });
    },
    updateStatus(item) {
      item.isLoading = true;
      this.updateStatusData(`${this.config.crudApi}/updateStatus`, item.id)
        .then(() => {
          this.getDataFromApi();
        })
        .finally(() => {
          item.isLoading = false;
        });
    },
    closeModal() {
      this.formData.id = 0;
      this.dialog = false;
      this.$validator.reset();
    },
    save() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        this.saveUpdateData(
          `${this.config.crudApi}/saveData`,
          `${this.config.crudApi}/updateData`,
          this.formData
        ).then(() => {
          this.getDataFromApi();
          this.closeModal();
        });
      });
    },
    getDataFromApi(options) {
      this.table.loading = true;
      this.getFrontEndData(`${this.config.crudApi}/getData`, options)
        .then((res) => {
          this.table.items = res.resources;
          this.table.length = Math.ceil(res.pagination.total / res.pagination.per_page);
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
     changePage(page) {
      let options = {
        page: page,
      };
      this.getDataFromApi(options);
    },
  },
  mounted() {
    this.getDataFromApi();

    if (
      (this.user &&
        this.user.permissions &&
        this.user.permissions.includes("update_permission")) ||
      (this.user &&
        this.user.permissions &&
        this.user.permissions.includes("delete_permission"))
    )
      this.table.headers.push({
        text: this.$t("Actions"),
        value: "actions",
        sortable: false,
        align: "center",
        width: "8rem",
      });
  },
};
</script>